import React, { useState } from "react";
import confetti from "canvas-confetti";
import styles from "./homepage.module.css"; // Assuming CSS Modules
import yetiimage from "../images/yeti1000.png"; // Correct path to your image

const HomePage = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false); // State to track if form has been submitted
  const [responseMessage, setResponseMessage] = useState(""); // To store the full response message

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://yeti1000api.yieldyeti.com/submit_email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setSubmitted(true); // Mark as submitted to conditionally render the message
        setResponseMessage(
          `Congratulations on securing spot number ${data.id}!`
        );
        // Trigger confetti on successful form submission
        confetti({
          particleCount: 150,
          spread: 70,
          origin: { y: 0.6 },
        });
      } else {
        setResponseMessage(data.message);
      }
    } catch (error) {
      console.error("There was an error submitting the email:", error);
      setResponseMessage("An error occurred while submitting the email.");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={yetiimage} alt="Yeti" className={styles.yetiImage} />
        <div className={styles.formContainer}>
          <h1 className={styles.title}>Play | Win | Earn</h1>
          <p className={styles.subtitle}>Secure Your Spot In The Yetiverse.</p>
          {!submitted ? ( // Conditionally render form or success message
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.inputGroup}>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  className={styles.inputField}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className={styles.submitButton}>
                  Secure Spot
                </button>
              </div>
            </form>
          ) : (
            <p className={styles.responseMessage}>{responseMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
